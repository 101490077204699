<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>카지노&Slot</sub-title>
                <div class="cs_panel_button">
                    <button class="btn-casino" :class="{'btn-cs-active':type===1}"
                            @click="change(1)">카지노
                    </button>
                    <button class="btn-slot" :class="{'btn-cs-active':type===2}"
                            @click="change(2)">슬롯
                    </button>
                </div>

                <!--카지노-->
                <div class="casino_panel" v-if="type===1">
                    <div class="slot-btns">
                        <button class="btn-slot-comp bw3" v-for="(item,index) in casinoComp"
                                @click="getThirdPartyGameList(item.code)"
                                :class="{'btn-cs-active':currentCasinoCode == item.code}">
                            {{item.name}}
                        </button>
                    </div>
                    <div class="slot_game">
                        <div class="slot-item" v-for="(item,index) in casinoGameList" :key="index"
                             @click="openGame(currentCasinoCode,item.code)">
                            <img :src="item.img" alt="">
                            <span class="name">{{item.name_kor}}</span>
                        </div>
                    </div>

                </div>
                <!--슬롯-->
                <div class="casino_panel" v-if="type === 2">
                    <div class="slot-btns">
                        <button class="btn-slot-comp bw4" v-for="(item,index) in slotComp"
                                @click="getThirdPartyGameList(item.code)"
                                :class="{'btn-cs-active':currentSlotCode == item.code}">
                            {{item.name}}
                        </button>
                    </div>
                    <div class="search">
                        <input type="text" v-model="searchVal" @input="search" placeholder="슬롯 2글자 이상 검색">
                    </div>
                    <div class="slot_game">
                        <div class="slot-item" v-for="(item,index) in slotGameList" :key="index"
                             @click="openGame(currentSlotCode,item.code)" v-if="item.isshow">
                            <img :src="item.img" alt="">
                            <span class="name">{{item.name_kor}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--위로가기-->
        <scroll-top-comp></scroll-top-comp>
        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import ScrollTopComp from "../../components/ScrollTopComp";
    import SubTitle from "../../components/SubTitle";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {
        addMemberCasinoCash,
        getAccountBalance, getCasinoMaintaining,
        getGameList,
        getGameUrl,
        getLobbyUrl, subjectMemberCasinoCash
    } from "../../network/casinoHonorRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import {getReachGameUrl, getThirdPartyGameList} from "../../network/casinoReachRequest";

    export default {
        name: "CasinoSlotGameReach",
        mixins: [postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SportsBetCartComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            FootComp, TopbarComp, SubTitle, ScrollTopComp, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                casinoComp: [
                    {'code': '1', 'name': '에볼루션'},
                    {'code': '5', 'name': 'CQ9라이브'},
                    {'code': '8', 'name': '드림게임'},
                    {'code': '12', 'name': 'WM라이브'},
                    {'code': '21', 'name': '에즈기'},
                    {'code': '29', 'name': '아시아게임'},
                    {'code': '33', 'name': '프라그마틱 라이브'},
                    {'code': '36', 'name': '섹시카지노'},
                    {'code': '46', 'name': 'BET게임즈tv'},
                    {'code': '47', 'name': '스카이윈드 라이브'},
                    {'code': '0', 'name': ''},
                    {'code': '0', 'name': ''},
                ],

                slotComp: [
                    {'code': '7', 'name': '플라그마틱'},
                    {'code': '3', 'name': '에보플레이'},
                    {'code': '4', 'name': 'CQ9'},
                    {'code': '9', 'name': '붕고'},
                    {'code': '10', 'name': '리얼타임'},
                    {'code': '17', 'name': '와즈단'},
                    {'code': '16', 'name': '퀵스핀'},
                    {'code': '20', 'name': '네펜트'},
                    {'code': '22', 'name': '레드타이거'},
                    {'code': '23', 'name': '플레이슨'},
                    {'code': '24', 'name': '플레이스타'},
                    {'code': '25', 'name': '하바네로'},
                    {'code': '26', 'name': '플레이고'},
                    {'code': '27', 'name': '릴렉스게임'},
                    {'code': '30', 'name': 'AG슬롯'},
                    {'code': '34', 'name': 'TP게임'},
                    {'code': '35', 'name': '게임ART'},
                    {'code': '37', 'name': 'PG소프트'},
                    {'code': '41', 'name': '천둥'},
                    {'code': '43', 'name': '모빌'},
                    {'code': '44', 'name': '플레이펄'},
                    {'code': '49', 'name': '1X2게임'},
                    {'code': '50', 'name': '엘크스튜디오'},
                    {'code': '0', 'name': ''},
                ],
                currentCasinoCode: '1',
                currentSlotCode: '7',
                casinoGameList: [],
                slotGameList: [],
                sportsConst,
                casinocash: 0,
                position: "Casino",
                searchVal: '',
                type: 1,
            }
        },
        methods: {
            change(type) {
                this.type = type
                if (this.type === 1) {
                    this.getThirdPartyGameList(this.currentCasinoCode)
                }
                if (this.type === 2) {
                    this.getThirdPartyGameList(this.currentSlotCode)
                }
            },
            search() {
                if (this.searchVal === '') {
                    this.slotGameList.forEach(g => {
                        this.$set(g, 'isshow', true)
                    });
                } else {
                    this.slotGameList.forEach(g => {
                        if (g.name_kor && g.name_kor.indexOf(this.searchVal) !== -1) {
                            this.$set(g, 'isshow', true)
                        } else {
                            this.$set(g, 'isshow', false)
                        }

                    });
                }
            },
            openGame(thirdPartyCode, gameCode) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                let platform = 'PC';
                getReachGameUrl(thirdPartyCode, gameCode, platform).then(res => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            getThirdPartyGameList(thirdPartyCode) {
                if (thirdPartyCode == '0') {
                    return false
                }
                if (this.type === 1) {
                    this.currentCasinoCode = thirdPartyCode
                }
                if (this.type === 2) {
                    this.currentSlotCode = thirdPartyCode
                }

                this.$store.commit(RECEIVE_SHOW_LOADING)
                getThirdPartyGameList(thirdPartyCode).then(res => {
                    if (res.data.success) {
                        if (this.type === 2) {
                            this.slotGameList = res.data.data;
                            this.slotGameList.forEach(g => {
                                this.$set(g, 'isshow', true)
                            });
                        }
                        if (this.type === 1) {
                            this.casinoGameList = res.data.data;
                            this.casinoGameList.forEach(g => {
                                this.$set(g, 'isshow', true)
                            });
                        }
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                });
            }

        },
        created() {
            if (this.$store.state.isLogin) {
                getCasinoMaintaining().then(res => {
                    if (res.data.success) {
                        if (!res.data.data) {
                            this.$swal({
                                title: '카지노 점검중입니다',
                                type: 'error',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.getThirdPartyGameList(this.currentCasinoCode);
                        }
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }

                })
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .casino_panel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16px;
    }

    .casino_panel .slot-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 5px;
    }

    .casino_panel .btn-slot-comp {
        background-color: var(--btn01Bg);
        margin-bottom: 2px;
        height: 32px;
        border-radius: 1px;
        color: #fff9ff;
        font-weight: normal;
    }

    .btn-cs-active {
        color: #fff9ff !important;
        background-color: var(--sportsKindButtonBgActive) !important;
    }

    .casino_panel .search {
        width: 100%;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .casino_panel .search input {
        color: #0c0e0e;
        background-color: #cfcfd1;
        height: 32px;
        text-align: center;
        width: 100%;
        border: none;
        -webkit-appearance: none; /*去除阴影边框*/
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /*点击高亮的颜色*/
    }

    .cs_panel_button {
        margin: 20px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-casino {
        --border: 1px solid var(--loginBtnBg);
        border-radius: 1px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
    }

    .btn-slot {
        --border: 2px solid var(--loginBtnBg);
        border-radius: 1px;
        padding: 10px 0;
        width: 50%;
        margin: 0 5px;
    }

    @media screen and (max-width: 1024px) {

    }

    .button_group a {
        margin-top: 3px;
    }


    .casino_panel .casino {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 320px;
        width: 100%;
    }

    .casino_panel .casino div {
        width: 19.7%;
        box-sizing: border-box;
        padding: 10px 10px;
        text-align: center;
    }

    .casino_panel .casino {
        max-width: 100%;
        cursor: pointer;
    }

    .casino img {
        width: 100%;
        border: 1px solid var(--loginBtnBg);
        border-radius: 4px;
    }

    .casino_panel .slot_company {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
        margin: 10px auto;
    }


    .casino_panel .slot_game {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        margin-top: 10px;
    }

    .casino_panel .slot_game .slot-item {
        min-width: 150px;
        max-width: 200px;
        border-bottom: 1px solid #4a4a4a;
        cursor: pointer;
        margin: 5px 5px;
        height: 32px;
    }

    .casino_panel .slot_game .name {
        display: block;
        line-height: 50px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #fff9ff;
    }

    .casino_panel .slot_game img {
        width: 100%;
        border: 1px solid #f4c600;
        border-radius: 5px;
    }

    @media screen and (max-width: 800px) {
        .casino div {
            width: 100% !important;
            box-sizing: border-box;
            padding: 10px 10px;
        }

        .casino_panel .slot_game div {
            width: 45.5%;
        }

        .casino_panel .slot_company a {
            width: 48% !important;
        }

        .casino_panel .board_box {
            width: 100% !important;
        }

    }
</style>